import { useEffect, useRef, useCallback, useState } from "react";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import * as MapboxDrawGeodesic from "mapbox-gl-draw-geodesic";
import { POLYGON_DRAW_STYLES, POLYGON_MAX_ACREAGE } from "../constants";
import { polygon as turfPolygon } from "@turf/helpers";
import area from "@turf/area";
import { useApp } from "../../../../../AppProvider";
import { fitMapToPolygonBounds } from "../components/PolygonToolControl";

export const calculatePolygonArea = (coordinates) => {
  if (!coordinates || coordinates.length === 0) {
    return 0;
  }

  const geoPolygon = turfPolygon(coordinates);
  const areaInSquareMeters = area(geoPolygon);

  return areaInSquareMeters / 4046.8564224;
};

const usePolygonTool = (map, polygon, setPolygonInUrl, toolOpen) => {
  const drawControlRef = useRef(null);
  const hasRun = useRef(false);
  const [isDrawing, setIsDrawing] = useState(false);
  const [drawnPolygonArea, setDrawnPolygonArea] = useState(0);
  const { doToast } = useApp();

  const handlePolygonArea = useCallback(
    (coordinates) => {
      const areaInAcres = parseFloat(
        calculatePolygonArea(coordinates).toFixed(2)
      );
      setDrawnPolygonArea(areaInAcres);

      if (areaInAcres > POLYGON_MAX_ACREAGE) {
        doToast(
          "error",
          `Query area is ${areaInAcres} acres, which exceeds the maximum allowed size of ${POLYGON_MAX_ACREAGE} acres.`,
          { duration: 12000 }
        );
      }

      return areaInAcres;
    },
    [doToast]
  );

  useEffect(() => {
    if (!map || !toolOpen || drawControlRef.current) return;

    if (!drawControlRef.current) {
      const modes = MapboxDraw.modes;
      const extendedModes = MapboxDrawGeodesic.enable(modes);

      drawControlRef.current = new MapboxDraw({
        modes: extendedModes,
        displayControlsDefault: false,
        userProperties: true,
        styles: POLYGON_DRAW_STYLES,
      });

      map.addControl(drawControlRef.current);
    }

    if (polygon) {
      try {
        if (!hasRun.current) {
          fitMapToPolygonBounds(map, polygon);
        }
        hasRun.current = true;

        drawControlRef.current.deleteAll();

        const polygonFeature = {
          id: "userPolygon",
          type: "Feature",
          properties: {},
          geometry: {
            type: "Polygon",
            coordinates: polygon,
          },
        };

        drawControlRef.current.add(polygonFeature);
        handlePolygonArea(polygon);
      } catch (error) {
        console.error("Invalid polygon coordinates:", error);
      }
    }

    return () => {
      if (
        map &&
        drawControlRef.current &&
        map.hasControl(drawControlRef.current)
      ) {
        map.removeControl(drawControlRef.current);
        drawControlRef.current = null;
      }
    };
  }, [map, polygon, toolOpen, handlePolygonArea]);

  const handleDrawCreateOrUpdate = useCallback(() => {
    const features = drawControlRef.current.getAll().features;
    if (features.length > 0) {
      const polygonFeature = features[0];

      if (features.length > 1) {
        const idsToDelete = features.slice(1).map((f) => f.id);
        drawControlRef.current.delete(idsToDelete);
      }

      const coordinates = polygonFeature.geometry.coordinates;
      setPolygonInUrl(coordinates);
      setIsDrawing(false);
      handlePolygonArea(coordinates);
    } else {
      setPolygonInUrl(null);
      setDrawnPolygonArea(0);
    }
  }, [setPolygonInUrl, handlePolygonArea]);

  useEffect(() => {
    if (!map || !drawControlRef.current) return;

    map.on("draw.create", handleDrawCreateOrUpdate);
    map.on("draw.update", handleDrawCreateOrUpdate);
    map.on("draw.delete", handleDrawCreateOrUpdate);

    return () => {
      map.off("draw.create", handleDrawCreateOrUpdate);
      map.off("draw.update", handleDrawCreateOrUpdate);
      map.off("draw.delete", handleDrawCreateOrUpdate);
    };
  }, [map, handleDrawCreateOrUpdate]);

  const startPolygonDraw = useCallback(() => {
    if (isDrawing) {
      drawControlRef.current.changeMode("simple_select");
      setIsDrawing(false);
    } else {
      setIsDrawing(true);
      drawControlRef.current.deleteAll();
      setPolygonInUrl(null);
      setDrawnPolygonArea(0);
      setTimeout(() => {
        drawControlRef.current.changeMode("draw_polygon");
      }, 100);
    }
  }, [isDrawing, setPolygonInUrl]);

  return {
    startPolygonDraw,
    isDrawing,
    drawnPolygonArea,
  };
};

export default usePolygonTool;
