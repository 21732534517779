//TODO document this
//TODO clean up public map dynamic filters, toggles, and controls

export const BASEMAP_STYLES = [
  {
    style: "standard-satellite",
    name: "Standard Satellite",
    url: "mapbox://styles/mapbox/standard-satellite",
  },
  {
    style: "standard",
    name: "Standard",
    url: "mapbox://styles/mapbox/standard",
  },
  {
    style: "streets-v12",
    name: "Streets",
    url: "mapbox://styles/mapbox/streets-v12",
  },
  {
    style: "outdoors-v12",
    name: "Outdoors",
    url: "mapbox://styles/mapbox/outdoors-v12",
  },
  {
    style: "light-v11",
    name: "Light",
    url: "mapbox://styles/mapbox/light-v11",
  },
  {
    style: "dark-v11",
    name: "Dark",
    url: "mapbox://styles/mapbox/dark-v11",
  },
  {
    style: "satellite-v9",
    name: "Satellite",
    url: "mapbox://styles/mapbox/satellite-v9",
  },
  {
    style: "satellite-streets-v12",
    name: "Satellite Streets",
    url: "mapbox://styles/mapbox/satellite-streets-v12",
  },
  {
    style: "navigation-day-v1",
    name: "Navigation Day",
    url: "mapbox://styles/mapbox/navigation-day-v1",
  },
  {
    style: "navigation-night-v1",
    name: "Navigation Night",
    url: "mapbox://styles/mapbox/navigation-night-v1",
  },
];
export const BASEMMAP_IMAGE_CENTER = [-97.35836, 41.42911];
export const DEFAULT_MAP_CENTER = [-97.67667, 41.61364];
export const DEFAULT_MAP_ZOOM = 9.2;
export const DEFAULT_MAP_BASEMAP = BASEMAP_STYLES[0].url;

export const INIT_MAP_CONFIG = {
  style: DEFAULT_MAP_BASEMAP,
  center: DEFAULT_MAP_CENTER,
  zoom: DEFAULT_MAP_ZOOM,
  preserveDrawingBuffer: true,
  attributionControl: true,
};

export const WELLS_LAYER_ID = "map-wells-circle";
export const WELLS_LABELS_LAYER_ID = "map-wells-symbol";
// a dynamic color by must be included in this list as well. if it should be colored but not filtered, just add the excludeFilter boolean
export const INIT_FILTER_VALUES = {
  counties: {
    label: "County",
    name: "counties",
    layerId: WELLS_LAYER_ID,
    layerFieldName: "county",
    options: [],
    type: "multi-select",
    value: [],
    excludeFilter: true,
  },
  depthRanges: {
    label: "Depth",
    name: "depthRanges",
    layerId: WELLS_LAYER_ID,
    layerFieldName: "depth_range_desc",
    options: [],
    type: "multi-select",
    value: [],
  },
  nrds: {
    label: "NRD",
    name: "nrds",
    layerId: WELLS_LAYER_ID,
    layerFieldName: "nrd_name",
    options: [],
    type: "multi-select",
    value: [],
  },
  status: {
    label: "Status",
    name: "status",
    layerId: WELLS_LAYER_ID,
    layerFieldName: "status",
    options: [],
    type: "multi-select",
    value: [],
  },
  uses: {
    label: "Use",
    name: "uses",
    layerId: WELLS_LAYER_ID,
    layerFieldName: "use",
    options: [],
    type: "multi-select",
    value: [],
  },
  aquiferType: {
    label: "Aquifer Type",
    name: "aquiferType",
    layerId: WELLS_LAYER_ID,
    layerFieldName: "completion_desc",
    options: [],
    type: "multi-select",
    value: [],
  },
  isHighcap: {
    label: "High Capacity?",
    name: "isHighcap",
    layerId: WELLS_LAYER_ID,
    layerFieldName: "is_highcap",
    type: "boolean",
    value: false,
  },
};

const layerId = "map-wells-circle";
export const INIT_STYLE_VALUES = {
  counties: {
    id: "counties",
    layerId,
    layerFieldName: "county",
    name: "County",
    paint: {
      "circle-color": [
        "match",
        ["get", "county"],
        "No Values Available",
        "#000000",
        "#000000",
      ],
    },
  },
  depthRanges: {
    id: "depthRanges",
    layerId,
    layerFieldName: "depth_range_desc",
    name: "Depth",
    paint: {
      "circle-color": [
        "match",
        ["get", "depth_range_desc"],
        "No Values Available",
        "#000000",
        "#000000",
      ],
    },
  },
  nrds: {
    id: "nrds",
    layerId,
    layerFieldName: "nrd_name",
    name: "NRD",
    paint: {
      "circle-color": [
        "match",
        ["get", "nrd_name"],
        "No Values Available",
        "#000000",
        "#000000",
      ],
    },
  },
  status: {
    id: "status",
    defaultLoad: true,
    layerId,
    layerFieldName: "status",
    name: "Status",
    paint: {
      "circle-color": [
        "match",
        ["get", "status"],
        "No Values Available",
        "#000000",
        "#000000",
      ],
    },
  },
  uses: {
    id: "uses",
    layerId,
    layerFieldName: "use",
    name: "Use",
    paint: {
      "circle-color": [
        "match",
        ["get", "use"],
        "No Values Available",
        "#000000",
        "#000000",
      ],
    },
  },
  aquiferType: {
    id: "aquiferType",
    layerId,
    layerFieldName: "completion_desc",
    name: "Aquifer Type",
    paint: {
      "circle-color": [
        "match",
        ["get", "completion_desc"],
        "No Values Available",
        "#000000",
        "#000000",
      ],
    },
  },
};

export const SEARCH_CONFIG = {
  "map-wells": {
    id: "map-wells",
    label: "Wells",
    key: "well_ndx",
    searchableFields: ["well_id", "nrd_name", "owner_name"],
    searchPlaceholder: "Search Wells",
    displayFields: [
      { field: "well_id", label: "Well ID" },
      { field: "nrd_name", label: "NRD" },
      { field: "owner_name", label: "Owner" },
      { field: "county", label: "County" },
      { field: "status", label: "Status" },
      { field: "use", label: "Use" },
      { field: "well_depth", label: "Depth" },
    ],
  },
};

// This is the order in which the layer accordions will be displayed in the legend, if not defined here, the remaining items will be order alphabetically
export const ACCORDION_GROUPS_ORDER = [
  "Primary Map Layers",
  "Hydrogeology",
  "General Map Layers",
];
