import React, { memo, useEffect, useMemo, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Box,
  Checkbox,
  IconButton,
  List,
  ListItem as MuiListItem,
  ListItemText,
  Slider,
  Typography,
  Accordion,
  AccordionSummary,
  ListItemIcon,
  ButtonBase,
  Tooltip,
} from "@mui/material";
import { ExpandMore, ChevronRight, InsertLink } from "@mui/icons-material";

import { styled } from "@mui/material/styles";
import { customSecondary } from "../../../../theme/variants";
import useBreakpoints from "../../../../hooks/useBreakpoints";
import { ACCORDION_GROUPS_ORDER } from "../../constants";

const SidebarSection = styled(Typography)(({ theme }) => ({
  color: customSecondary[500],
  padding: theme.spacing(1, 5, 0),
  opacity: 0.9,
  fontWeight: theme.typography.fontWeightBold,
  display: "block",
}));

const ListItem = styled(MuiListItem)({
  paddingTop: 0,
  paddingBottom: 0,
});

const getLegendOptions = (layer) => {
  const colorProperty = `${layer?.type}-color`;
  const color = layer?.paint?.[colorProperty];
  const units = layer?.lreProperties?.legend?.units
    ? ` ${layer.lreProperties.legend.units}`
    : "";
  const optionsTextOverrides =
    layer?.lreProperties?.legend?.optionsTextOverrides || {};

  if (!Array.isArray(color)) {
    const overriddenText = optionsTextOverrides[layer.name] || layer.name;
    return [{ color, text: overriddenText }];
  }

  const [type, ...expression] = color;
  if (type === "interpolate") {
    const stops = expression.slice(2);
    const legendOptions = stops.reduce((acc, stop, index) => {
      if (index % 2 === 0 && stops[index + 2] !== undefined) {
        const rangeLabel =
          index === 0
            ? `${stop} - ${stops[index + 2]}${units}`
            : `> ${stop} - ${stops[index + 2]}${units}`;
        acc.push({
          color: stops[index + 1],
          text: optionsTextOverrides[stop] || rangeLabel,
        });
      }
      return acc;
    }, []);
    const lastStop = stops[stops.length - 2];
    const lastColor = stops[stops.length - 1];
    legendOptions.push({
      color: lastColor,
      text: optionsTextOverrides[lastStop] || `> ${lastStop}${units}`,
    });
    return legendOptions;
  }

  if (type === "match") {
    const matches = expression.slice(1, -1);
    const defaultColor = expression[expression.length - 1];
    const legendOptions = matches.reduce((acc, value, index) => {
      if (index % 2 === 0) {
        const overriddenText =
          optionsTextOverrides[value] || `${value}${units}`;
        acc.push({
          color: matches[index + 1],
          text: overriddenText,
        });
      }
      return acc;
    }, []);

    layer.lreProperties?.legend?.hideDefaultMatch !== true &&
      legendOptions.push({ color: defaultColor, text: "N/A" });
    return legendOptions;
  }

  const overriddenText = optionsTextOverrides[layer.name] || layer.name;
  return [{ color, text: overriddenText }];
};

const LegendSymbol = ({ color, symbolType = "circle" }) => {
  switch (symbolType) {
    case "line":
      return (
        <Box bgcolor={color} height={5} width={12} sx={{ minWidth: "12px" }} />
      );
    case "fill":
      return (
        <svg width="14" height="14" viewBox="0 0 14 14">
          <polygon
            points="2,3 12,2 11,11 3,12"
            fill={`rgba(${hexToRgb(color)}, 0.5)`}
            stroke={color}
            strokeWidth="1.5"
          />
        </svg>
      );
    case "circle":
    default:
      return (
        <Box
          bgcolor={color}
          borderRadius="50%"
          height={12}
          width={12}
          sx={{ minWidth: "12px" }}
        />
      );
  }
};

// Helper function to convert hex color to RGB for proper transparency handling
const hexToRgb = (hex) => {
  hex = hex.replace(/^#/, "");
  let bigint = parseInt(hex, 16);
  let r = (bigint >> 16) & 255;
  let g = (bigint >> 8) & 255;
  let b = bigint & 255;
  return `${r}, ${g}, ${b}`;
};

const LayerLegendIcon = ({ open }) =>
  open ? <ExpandMore /> : <ChevronRight />;

const shouldRenderOpacitySlider = (item) => {
  const sliderDisabled =
    item?.lreProperties?.legend?.fillOpacitySlider === false;
  const hasValidOpacity =
    item?.type === "fill" && typeof item.paint?.["fill-opacity"] === "number";
  return !sliderDisabled && hasValidOpacity;
};

const LayerSlider = ({ item, onOpacityChange }) => {
  const layerFillOpacity = item.paint?.["fill-opacity"];
  const [sliderValue, setSliderValue] = useState(
    +(layerFillOpacity * 100).toFixed(0) ?? 0
  );

  const handleSliderChange = (event, newValue) => setSliderValue(newValue);
  const handleSliderCommit = (event, newValue) =>
    onOpacityChange({ id: item.id, opacity: newValue / 100 });

  return (
    <Box mb={-2}>
      <SidebarSection>Fill Opacity</SidebarSection>
      <Slider
        color="secondary"
        valueLabelDisplay="auto"
        value={sliderValue}
        onChange={handleSliderChange}
        onChangeCommitted={handleSliderCommit}
        aria-labelledby="continuous-slider"
      />
    </Box>
  );
};

const LayerLegend = ({ item, open, onOpacityChange }) => {
  const { isSm } = useBreakpoints();
  if (!open) return null;
  const legendItems = getLegendOptions(item);
  const symbolType =
    item?.lreProperties?.legend?.symbolType || item.type || "circle";
  return (
    <Box
      display="flex"
      flexDirection="column"
      rowGap="4px"
      mb={2}
      mx={isSm ? "18px" : 11}
    >
      {legendItems.map(({ color, text }) => (
        <Box
          className="print-legend"
          key={text}
          display="flex"
          alignItems="center"
          columnGap="8px"
        >
          <LegendSymbol color={color} symbolType={symbolType} />
          <Typography color="textSecondary" variant="body2">
            {text}
          </Typography>
        </Box>
      ))}
      {shouldRenderOpacitySlider(item) && (
        <LayerSlider item={item} onOpacityChange={onOpacityChange} />
      )}
    </Box>
  );
};

const LayerLink = ({ item, layerVisible }) => {
  const { url, newTab = true } = item?.lreProperties?.legend?.link;

  const isExternal = url.startsWith("http");

  const linkProps = isExternal
    ? {
        component: "a",
        href: url,
        target: newTab ? "_blank" : "_self",
        rel: "noopener noreferrer",
      }
    : { component: RouterLink, to: url };

  return (
    <Tooltip
      title={newTab ? "Opens in a new tab" : "Opens in the same tab"}
      arrow
    >
      <ButtonBase {...linkProps} sx={{ width: "100%" }}>
        <ListItem sx={{ cursor: "pointer" }}>
          <ListItemIcon>
            <InsertLink />
          </ListItemIcon>
          <ListItemText
            primary={item.name}
            primaryTypographyProps={{
              color: layerVisible ? "textPrimary" : "textSecondary",
            }}
          />
        </ListItem>
      </ButtonBase>
    </Tooltip>
  );
};

const LayersControl = ({ items, onLayerChange, onOpacityChange }) => {
  const [expandedItems, setExpandedItems] = useState([]);
  const [expandedAccordions, setExpandedAccordions] = useState([]);

  useEffect(() => {
    if (items.length > 0) {
      const visibleItems = items.filter(
        (item) => item.layout.visibility === "visible"
      );

      // Include visible items (checked items) and remove hidden ones
      setExpandedItems((prevExpandedItems) => {
        const newExpandedItems = visibleItems.map((item) => item.name);
        // Merge previous expanded items with newly visible items
        return [...new Set([...prevExpandedItems, ...newExpandedItems])].filter(
          (itemName) => newExpandedItems.includes(itemName)
        );
      });

      // Open accordions based on visible items
      setExpandedAccordions((prevExpandedAccordions) => {
        const visibleGroups = [
          ...new Set(
            visibleItems
              .map((item) => item?.lreProperties?.legend?.accordionGroup)
              .filter(Boolean)
          ),
        ];

        // expandedAccordions opens based on visible items but does not close
        const updatedAccordions = new Set([
          ...prevExpandedAccordions,
          ...visibleGroups,
        ]);
        return [...updatedAccordions];
      });
    }
  }, [items]);

  const { uniqueItems, uniqueAccordionGroups } = useMemo(() => {
    const itemMap = new Map();
    const accordionGroupSet = new Set();

    items.forEach((item) => {
      const key = item?.lreProperties?.legend?.labelGroup || item.id;

      if (!itemMap.has(key)) {
        // Assign 'Other' if accordionGroup is not defined
        if (!item?.lreProperties?.legend?.accordionGroup) {
          item.lreProperties.legend.accordionGroup = "Other";
        }

        itemMap.set(key, item);
        accordionGroupSet.add(item.lreProperties.legend.accordionGroup);
      }
    });

    // Convert Set to Array and sort based on ACCORDION_GROUPS_ORDER
    const sortedAccordionGroups = [...accordionGroupSet].sort((a, b) => {
      const indexA = ACCORDION_GROUPS_ORDER.indexOf(a);
      const indexB = ACCORDION_GROUPS_ORDER.indexOf(b);

      if (indexA !== -1 && indexB !== -1) {
        return indexA - indexB;
      }
      if (indexA !== -1) return -1;
      if (indexB !== -1) return 1;

      return a.localeCompare(b);
    });

    const sortedUniqueItems = Array.from(itemMap.values()).sort((a, b) => {
      const orderA = a?.lreProperties?.legend?.order ?? 0;
      const orderB = b?.lreProperties?.legend?.order ?? 0;
      return orderA - orderB;
    });

    return {
      uniqueItems: sortedUniqueItems,
      uniqueAccordionGroups: sortedAccordionGroups,
    };
  }, [items]);

  const toggleVisibility = (item) =>
    onLayerChange({
      id: item?.lreProperties?.legend?.labelGroup || item.id,
      visible: item.layout.visibility === "none",
    });
  const toggleExpandedItem = (value) =>
    setExpandedItems((prev) =>
      prev.includes(value) ? prev.filter((i) => i !== value) : [...prev, value]
    );

  return (
    <Box display="flex" flexDirection="column">
      <List dense>
        {!uniqueItems?.length && (
          <Box textAlign="center">
            <Typography variant="body1">No layers found</Typography>
          </Box>
        )}
        {uniqueAccordionGroups.map((group) => (
          <Accordion
            key={group}
            expanded={expandedAccordions.includes(group)}
            onChange={(e, isExpanded) =>
              setExpandedAccordions((prev) =>
                isExpanded ? [...prev, group] : prev.filter((g) => g !== group)
              )
            }
          >
            <AccordionSummary
              sx={{ display: "flex", flexDirection: "row-reverse" }}
              expandIcon={<ExpandMore />}
            >
              <Typography variant="subtitle1">{group}</Typography>
            </AccordionSummary>
            {uniqueItems
              .filter(
                (uniqueItem) =>
                  uniqueItem.lreProperties?.legend?.accordionGroup === group
              )
              .map((item) => {
                const open = expandedItems.includes(item?.name);
                const visible = item.layout.visibility === "visible";
                return (
                  <Box key={item?.name}>
                    {item?.lreProperties?.legend?.link?.url ? (
                      // Handle layers with popout links (either external or internal)
                      <LayerLink item={item} layerVisible={visible} />
                    ) : (
                      // Handle layers without popout links
                      <ListItem
                        onClick={() => {
                          toggleVisibility(item);
                          if ((!visible && !open) || (visible && open)) {
                            toggleExpandedItem(item.name);
                          }
                        }}
                      >
                        <Checkbox
                          edge="start"
                          checked={visible}
                          disableRipple
                        />
                        <ListItemText
                          primary={item?.name}
                          primaryTypographyProps={{
                            color: visible ? "textPrimary" : "textSecondary",
                          }}
                        />
                        {!item?.lreProperties?.legend?.disableCollapse && (
                          <Box mr={1}>
                            <IconButton
                              edge="end"
                              onClick={(e) => {
                                e.stopPropagation();
                                toggleExpandedItem(item.name);
                              }}
                            >
                              <LayerLegendIcon open={open} />
                            </IconButton>
                          </Box>
                        )}
                      </ListItem>
                    )}
                    {!item?.lreProperties?.legend?.disableCollapse && (
                      <LayerLegend
                        open={open}
                        item={item}
                        onOpacityChange={onOpacityChange}
                      />
                    )}
                  </Box>
                );
              })}
          </Accordion>
        ))}
      </List>
    </Box>
  );
};

export default memo(LayersControl);
